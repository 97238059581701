import React, { useEffect, useState, useMemo } from 'react';
import {
  addDoc,
  setDoc,
  collection,
  doc,
  getDoc,
  Timestamp,
} from 'firebase/firestore';
import { nanoid } from 'nanoid';
import styled from 'styled-components';
import { Asset, EditingConfig, Overlay, Utterance } from 'shared-models';
import { API_URL } from 'src/utils/api';
import { auth, db, analytics } from '../firebase';
import { theme } from '../styles/theme';
import { Grid } from '../styles/VideoList.style';
import EditingDialog from './EditingDialog';
import Spinner from './Spinner';
import VideoCard from './VideoCard';
import { logEvent } from 'firebase/analytics';

const ASSETS_COLLECTION = `assets-experimental`;
const EDIT_REQUESTS_COLLECTION = `edit_requests`;
const HUMAN_ANNOTATIONS_COLLECTION = `human_annotations`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const PageButton = styled.button<{ $active?: boolean }>`
  margin: 0 5px;
  padding: 5px 10px;
  background-color: ${(props) =>
    props.$active ? theme.colors.secondary : theme.colors.primary};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const PageInfo = styled.span`
  margin: 0 10px;
`;

interface VideoListProps {
  assetIds: { id: string }[];
  filterType: string;
  filterValue: string;
}


const filterAssets = (assets: Asset[], filterType: string, filterValue: string) => {
  if (filterType === 'none' || !filterValue) return assets;

  return assets.filter((asset) => {
    const lowerFilterValue = filterValue.toLowerCase();
    switch (filterType) {
      case 'search':
        const description = asset.annotations?.description?.toLowerCase() || '';
        const transcript =
          asset.transcription?.map((u) => u.text.toLowerCase()).join(' ') || '';
        return description.includes(lowerFilterValue) || transcript.includes(lowerFilterValue);
      case 'machine_topics':
        return asset.annotations?.topics?.some((topic) =>
          topic.toLowerCase().includes(lowerFilterValue),
        );

      case 'machine_speakers':
        return asset.annotations?.speakers
        ? Object.values(asset.annotations.speakers).some((speaker) =>
            speaker.toLowerCase().includes(lowerFilterValue)
          )
        : false;
      case 'machine_vibes':
        return asset.annotations?.vibes?.some((vibe) =>
          vibe.toLowerCase().includes(lowerFilterValue),
        );
      case HUMAN_ANNOTATIONS_COLLECTION:
        return Object.entries(asset.human_annotations || {}).some(
          ([key, value]) =>
            key.toLowerCase().includes(lowerFilterValue) ||
            String(value).toLowerCase().includes(lowerFilterValue),
        );
      default:
        return true;
    }
  });
};

export type CheckedUtterancesType = boolean[];
export type AssetEditorState = {
  id: string;
  checkedUtterances: CheckedUtterancesType;
};

async function fetchAsset(id: string): Promise<Asset | null> {
  try {
    const docRef = doc(db, ASSETS_COLLECTION, id);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
      console.warn(`Asset with ID ${id} does not exist.`);
      return null;
    }

    const humanAnnotationsDoc = await getDoc(doc(db, HUMAN_ANNOTATIONS_COLLECTION, id));
    const { preview, ...human_annotations } = humanAnnotationsDoc.data() ?? {};

    return { ...(docSnap.data() as Asset), human_annotations };
  } catch (error) {
    console.error(`Error fetching asset with ID ${id}:`, error);
    return null;
  }
}


const VideoList: React.FC<VideoListProps> = ({ assetIds, filterType, filterValue }) => {
  const [assetEditorState, setAssetEditorState] = useState<AssetEditorState | null>(null);
  const [overlays, setOverlays] = useState<Overlay[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;
  const [assets, setAssets] = useState<Record<string, Asset>>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let ignore = false;
    setLoading(true);

    async function fetchData() {
      const assetPromises = assetIds.map(({ id }) => fetchAsset(id));
      const fetchedAssets = await Promise.all(assetPromises);
      if (!ignore) {
        const validAssets = fetchedAssets.filter((asset): asset is Asset => asset !== null);
        const newAssets = Object.fromEntries(validAssets.map((asset) => [asset.id, asset]));
        setAssets(newAssets);
        setLoading(false);
      }
    }

    fetchData();

    return () => {
      ignore = true;
    };
  }, [assetIds]);

  const filteredAssets = useMemo(() => {
    return filterAssets(Object.values(assets), filterType, filterValue);
  }, [assets, filterType, filterValue]);

  const sortedAssets = useMemo(() => {
    return filteredAssets.sort((a, b) => b.metadata.date_processed - a.metadata.date_processed);
  }, [filteredAssets]);

  const totalPages = Math.ceil(sortedAssets.length / itemsPerPage);

  const currentItems = useMemo(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return sortedAssets.slice(indexOfFirstItem, indexOfLastItem);
  }, [sortedAssets, currentPage]);

  const changePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const renderPagination = () => {
    if (totalPages <= 1) return null;

    const pageNumbers = [];
    const maxVisiblePages = 5;

    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return (
      <PaginationContainer>
        <PageButton onClick={() => changePage(currentPage - 1)} disabled={currentPage === 1}>
          &lt;
        </PageButton>
        {pageNumbers.map((number) => (
          <PageButton
            key={number}
            onClick={() => changePage(number)}
            $active={number === currentPage}
          >
            {number}
          </PageButton>
        ))}
        <PageButton
          onClick={() => changePage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          &gt;
        </PageButton>
        <PageInfo>
          Page {currentPage} of {totalPages}
        </PageInfo>
      </PaginationContainer>
    );
  };

  useEffect(() => {
    const fetchOverlays = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          throw new Error('User not authenticated');
        }

        const idToken = await user.getIdToken();
        const response = await fetch(`${API_URL}/overlays`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch overlays');
        }
        const data = await response.json();
        setOverlays(data);
      } catch (error) {
        console.error('Error fetching overlays:', error);
      }
    };

    fetchOverlays();
  }, []);

  const handleSubmitClick = (state: AssetEditorState) => {
    setAssetEditorState(state);
  };

  const handleDialogClose = () => {
    setAssetEditorState(null);
  };

  const handleSubmitAsset = async (config: EditingConfig) => {
  if (assetEditorState === null) return;

  const { id: currentAssetId, checkedUtterances } = assetEditorState;

  const asset = assets[currentAssetId];
  if (!asset) {
    console.error('Asset not found');
    handleDialogClose();
    return;
  }

  if (!asset.transcription) {
    console.error('No transcription available for this asset');
    handleDialogClose();
    return;
  }

  const transcription = asset.transcription; // Capture the transcription in a local variable

  const selectedUtterances = (checkedUtterances || [])
    .map((checked, index) =>
      checked && index < transcription.length ? transcription[index] : null,
    )
    .filter((utterance): utterance is Utterance => utterance !== null);

  const user = auth.currentUser;
  if (!user) {
    alert('You must be logged in to submit an asset.');
    handleDialogClose();
    return;
  }

  try {
    const idToken = await user.getIdToken();
    const editRequestId = nanoid(21);

    const data = {
      id: editRequestId,
      asset_id: asset.id,
      selected_utterances: selectedUtterances,
      editing_config: config,
    };

    const editRequestData = {
      id: editRequestId,
      assetId: asset.id,
      userId: user.uid,
      status: 'submitted',
      createdAt: Timestamp.now(),
      selectedUtterances,
      config,
      read: false,
    };

    await setDoc(doc(db, EDIT_REQUESTS_COLLECTION, editRequestId), editRequestData);
    console.log('Edit request created with Document ID: ', editRequestId);

    const response = await fetch(`${API_URL}/edit_video`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error('Failed to submit video editing job');
    }

    const result = await response.json();
    console.log('Job submitted successfully:', result);

    logEvent(analytics, 'video_edit_request', {
      asset_id: asset.id,
      edit_request_id: editRequestId,
      utterance_count: selectedUtterances.length,
      config: JSON.stringify(config),
      user_id: user.uid,
    });
    alert('Video editing job submitted. You will be notified when it is complete.');
  } catch (error) {
    console.error('Error submitting video editing job:', error);
    logEvent(analytics, 'video_edit_request_error', {
      asset_id: asset.id,
      error_message: error instanceof Error ? error.message : String(error),
    });
    alert('Failed to submit video editing job. Please try again.');
  }
  handleDialogClose();
};

  if (loading) {
    return <Spinner />;
  }

  if (filteredAssets.length === 0) {
    return <div>No assets found.</div>;
  }

  if (filteredAssets.length === 0) {
    return <div>No assets found.</div>;
  }

  return (
    <>
      <Grid>
        {currentItems.map((asset) => (
          <VideoCard
            key={asset.id}
            asset={asset}
            handleSubmitClick={handleSubmitClick}
            searchTerm={filterType === 'search' ? filterValue : ''}
          />
        ))}
      </Grid>

      <EditingDialog
        open={assetEditorState != null}
        onClose={handleDialogClose}
        onSubmit={handleSubmitAsset}
        overlays={overlays}
      />

      {sortedAssets.length > 0 && renderPagination()}
    </>
  );
};

export default VideoList;

