export const theme = {
  colors: {
    primary: '#4A90E2',
    secondary: '#50E3C2',
    background: '#F5F5F5',
    text: '#333',
    lightText: '#777',
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: '16px',
    headerSize: '2em',
    subheaderSize: '1.5em',
  },
};
