import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import {
  addDoc,
  and,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { overlay_example, sa_video_example, voa_image_example, voa_video_example } from '../blocks';
import { auth, db, analytics } from '../firebase';
import { logEvent } from 'firebase/analytics';
import { Organization, useOrganizations } from '../organizationUtils';
import {
  trackAgentCreation,
  trackAgentUpdate,
  trackAgentDeletion,
  trackAgentDialogOpen,
  trackAgentDialogClose,
  trackAgentViewContent
} from '../utils/analyticsHelpers';

interface User {
  userId: string;
  name: string;
  email: string;
}

// Define the necessary types
interface Block {
  description: string;
  // Add other properties as needed
}

interface Overlay {
  id: string;
  description: string;
  // Add other properties as needed
}

interface InfluencerDefinition {
  id: string;
  theme: string;
  topics: string[];
  directive: string;
  prompt: string;
  overlays: Overlay[];
  construction: Block[];
  userId?: string;
  videoScaling: 'scaled' | 'letterbox';
  faceTracking: boolean;
  captioning: boolean;
  organizationId: string;
}

const INFLUENCER_AGENTS_COLLECTION = `influencer_agents`;
const USERS_COLLECTION = `users`;
const OVERLAYS_COLLECTION = `overlays`;

const blockOptions: Block[] = [
  overlay_example,
  voa_image_example,
  sa_video_example,
  voa_video_example,
];

const InfluencerAgents: React.FC = () => {
  const [userAgents, setUserAgents] = useState<InfluencerDefinition[]>([]);
  const [orgAgents, setOrgAgents] = useState<InfluencerDefinition[]>([]);
  const [open, setOpen] = useState(false);
  const [currentAgent, setCurrentAgent] = useState<InfluencerDefinition | null>(null);
  const [overlays, setOverlays] = useState<Overlay[]>([]);
  const { organizations, loading: orgLoading } = useOrganizations();
  const [users, setUsers] = useState<{ [key: string]: User }>({});
  const navigate = useNavigate();

  useEffect(() => {
    if (organizations.length > 0) {
      fetchAgents();
      fetchUsers();
      fetchOverlays();
    }
    
  }, [organizations]);

  const fetchAgents = async () => {
    const user = auth.currentUser;
    if (user && organizations.length > 0) {
      const agentsRef = collection(db, INFLUENCER_AGENTS_COLLECTION);

      // Fetch user-specific agents
      const userQuery = query(agentsRef, where('userId', '==', user.uid));
      const userSnapshot = await getDocs(userQuery);
      const userAgentList: InfluencerDefinition[] = userSnapshot.docs.map(
        (doc) => ({ ...doc.data(), id: doc.id }) as InfluencerDefinition,
      );
      setUserAgents(userAgentList);

      // Fetch organization-wide agents
      const orgQuery = query(
        agentsRef,
        and(
          where(
            'organizationId',
            'in',
            organizations.map((org) => org.id),
          ),
          where('userId', '!=', user.uid),
        ),
      );
      const orgSnapshot = await getDocs(orgQuery);
      const orgAgentList: InfluencerDefinition[] = orgSnapshot.docs.map(
        (doc) => ({ ...doc.data(), id: doc.id }) as InfluencerDefinition,
      );
      setOrgAgents(orgAgentList);
    }
  };

  const fetchUsers = async () => {
    const usersRef = collection(db, USERS_COLLECTION);
    const usersSnapshot = await getDocs(usersRef);
    const usersMap: { [key: string]: User } = {};
    usersSnapshot.forEach((doc) => {
      const userData = doc.data() as User;
      usersMap[userData.userId] = userData;
    });
    setUsers(usersMap);
  };

  const fetchOverlays = async () => {
    const overlaysCollection = collection(db, OVERLAYS_COLLECTION);
    const overlaysSnapshot = await getDocs(overlaysCollection);
    const overlayList: Overlay[] = [];
    overlaysSnapshot.forEach((doc) => {
      overlayList.push({ ...doc.data(), id: doc.id } as Overlay);
    });
    setOverlays(overlayList);
  };

  const handleOpen = (agent: InfluencerDefinition | null = null) => {
    setCurrentAgent(agent);
    setOpen(true);
    trackAgentDialogOpen(agent ? 'edit' : 'create');
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentAgent(null);
    trackAgentDialogClose('cancel');
  };

  const handleViewContent = (agent: InfluencerDefinition) => {
    trackAgentViewContent(agent.id!, agent.theme);
    navigate(`/generated-content/${encodeURIComponent(agent.id!)}`);
  };

  const handleSave = async (agent: InfluencerDefinition) => {
    const user = auth.currentUser;
    if (user) {
      const agentsRef = collection(db, INFLUENCER_AGENTS_COLLECTION);
      if (agent.id) {
        // Updating existing document
        const agentRef = doc(agentsRef, agent.id);
        await updateDoc(agentRef, { ...agent, userId: user.uid });
        trackAgentUpdate(agent.id, agent.theme, agent.organizationId);
      } else {
        // Creating new document
        const docRef = await addDoc(agentsRef, { ...agent, userId: user.uid });
        // Update the agent with the new ID
        await updateDoc(docRef, { id: docRef.id });
        trackAgentCreation(docRef.id, agent.theme, agent.organizationId);
      }
      fetchAgents();
      handleClose();
      trackAgentDialogClose('save');
    }
  };

  const handleDelete = async (id: string) => {
    await deleteDoc(doc(db, INFLUENCER_AGENTS_COLLECTION, id));
    fetchAgents();
  };

  const getOrganizationName = (orgId: string) => {
    const org = organizations.find((org) => org.id === orgId);
    return org ? org.name : 'Unknown Organization';
  };

  const renderAgentTable = (agents: InfluencerDefinition[], title: string) => (
    <>
      <Typography variant="h6" style={{ marginTop: '20px', marginBottom: '10px' }}>
        {title}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Theme</TableCell>
              <TableCell>Topics</TableCell>
              <TableCell>Directive</TableCell>
              <TableCell>Blocks</TableCell>
              <TableCell>Overlays</TableCell>
              <TableCell>Video Scaling</TableCell>
              <TableCell>Face Tracking</TableCell>
              <TableCell>Captioning</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Organization</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {agents.map((agent) => (
              <TableRow key={agent.id}>
                <TableCell>{agent.theme}</TableCell>
                <TableCell>{agent.topics.join(', ')}</TableCell>
                <TableCell>{agent.directive}</TableCell>
                <TableCell>
                  {agent.construction.map((block) => block.description).join(', ')}
                </TableCell>
                <TableCell>
                  {agent.overlays.map((overlay) => overlay.description).join(', ')}
                </TableCell>
                <TableCell>{agent.videoScaling}</TableCell>
                <TableCell>{agent.faceTracking ? 'Yes' : 'No'}</TableCell>
                <TableCell>{agent.captioning ? 'Yes' : 'No'}</TableCell>
                <TableCell>{users[agent.userId!]?.name || 'Unknown'}</TableCell>
                <TableCell>{getOrganizationName(agent.organizationId)}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleOpen(agent)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(agent.id!)}>
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleViewContent(agent)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  return (
    <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
      {orgLoading ? (
        <p>Loading organizations...</p>
      ) : (
        <>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => handleOpen()}
            style={{ marginBottom: '20px' }}
          >
            Add New Agent
          </Button>
          {renderAgentTable(userAgents, 'Your Influencer Agents')}
          {renderAgentTable(orgAgents, 'Organization Agents')}
          <AgentDialog
            open={open}
            onClose={handleClose}
            onSave={handleSave}
            agent={currentAgent}
            overlays={overlays}
            organizations={organizations}
          />
        </>
      )}
    </div>
  );
};

interface AgentDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (agent: InfluencerDefinition) => void;
  agent: InfluencerDefinition | null;
  overlays: Overlay[];
  organizations: Organization[];
}

const AgentDialog: React.FC<AgentDialogProps> = ({
  open,
  onClose,
  onSave,
  agent,
  overlays,
  organizations,
}) => {
  const [formData, setFormData] = useState<
    Omit<InfluencerDefinition, 'topics'> & { topics: string }
  >({
    id: '',
    theme: '',
    topics: '',
    directive: '',
    prompt: '',
    overlays: [],
    construction: [],
    videoScaling: 'scaled',
    faceTracking: false,
    captioning: false,
    organizationId: '',
  });

  useEffect(() => {
    if (agent) {
      setFormData({
        ...agent,
        id: agent.id,
        topics: Array.isArray(agent.topics) ? agent.topics.join(', ') : agent.topics || '',
        faceTracking: agent.faceTracking,
        captioning: agent.captioning,
      });
    } else {
      setFormData({
        id: '',
        theme: '',
        topics: '',
        directive: '',
        prompt: '',
        overlays: [],
        construction: [],
        videoScaling: 'scaled',
        faceTracking: false,
        captioning: false,
        organizationId: '',
      });
    }
  }, [agent]);

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleBooleanChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value === 'yes' }));
  };

  const handleVideoScalingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFormData((prev) => ({ ...prev, videoScaling: value as 'scaled' | 'letterbox' }));
  };

  const handleBlockChange = (event: SelectChangeEvent<string[]>) => {
    const selectedBlocks = event.target.value as string[];
    const constructionBlocks = blockOptions.filter((block) =>
      selectedBlocks.includes(block.description),
    );
    setFormData((prev) => ({ ...prev, construction: constructionBlocks }));
  };

  const handleOverlayChange = (event: SelectChangeEvent<string[]>) => {
    const selectedOverlays = event.target.value as string[];
    const selectedOverlayObjects = overlays.filter((overlay) =>
      selectedOverlays.includes(overlay.id),
    );
    setFormData((prev) => ({ ...prev, overlays: selectedOverlayObjects }));
  };

  const handleSubmit = () => {
    const submissionData: InfluencerDefinition = {
      ...formData,
      topics: formData.topics
        .split(',')
        .map((topic) => topic.trim())
        .filter((topic) => topic !== ''),
      videoScaling: formData.videoScaling as 'scaled' | 'letterbox',
    };
    onSave(submissionData);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography variant="h5">{agent ? 'Edit Agent' : 'Add New Agent'}</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="organization-label">Organization</InputLabel>
              <Select
                labelId="organization-label"
                name="organizationId"
                value={formData.organizationId}
                onChange={handleSelectChange}
                label="Organization"
              >
                {organizations.map((org) => (
                  <MenuItem key={org.id} value={org.id}>
                    {org.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Theme"
              name="theme"
              value={formData.theme}
              onChange={handleTextChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Topics"
              name="topics"
              value={formData.topics}
              onChange={handleTextChange}
              variant="outlined"
              helperText="Enter topics separated by commas"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Directive"
              name="directive"
              value={formData.directive}
              onChange={handleTextChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Prompt"
              name="prompt"
              value={formData.prompt}
              onChange={handleTextChange}
              variant="outlined"
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="overlays-label">Overlays</InputLabel>
              <Select
                labelId="overlays-label"
                multiple
                value={formData.overlays.map((overlay) => overlay.id)}
                onChange={handleOverlayChange}
                renderValue={(selected) =>
                  selected
                    .map((id) => overlays.find((o) => o.id === id)?.description || '')
                    .join(', ')
                }
                label="Overlays"
              >
                {overlays.map((overlay) => (
                  <MenuItem key={overlay.id} value={overlay.id}>
                    <Checkbox
                      checked={formData.overlays.map((o) => o.id).indexOf(overlay.id) > -1}
                    />
                    <ListItemText primary={overlay.description} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="blocks-label">Blocks</InputLabel>
              <Select
                labelId="blocks-label"
                multiple
                value={formData.construction.map((block) => block.description)}
                onChange={handleBlockChange}
                renderValue={(selected) => (selected as string[]).join(', ')}
                label="Blocks"
              >
                {blockOptions.map((block) => (
                  <MenuItem key={block.description} value={block.description}>
                    <Checkbox
                      checked={
                        formData.construction.map((b) => b.description).indexOf(block.description) >
                        -1
                      }
                    />
                    <ListItemText primary={block.description} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Video Scaling</FormLabel>
              <RadioGroup
                aria-label="video-scaling"
                name="videoScaling"
                value={formData.videoScaling}
                onChange={handleVideoScalingChange}
              >
                <FormControlLabel value="scaled" control={<Radio />} label="Scaled" />
                <FormControlLabel value="letterbox" control={<Radio />} label="Letterbox" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Face Tracking</FormLabel>
              <RadioGroup
                aria-label="face-tracking"
                name="faceTracking"
                value={formData.faceTracking ? 'yes' : 'no'}
                onChange={handleBooleanChange}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Captioning</FormLabel>
              <RadioGroup
                aria-label="captioning"
                name="captioning"
                value={formData.captioning ? 'yes' : 'no'}
                onChange={handleBooleanChange}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InfluencerAgents;
