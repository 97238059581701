import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { getAnalytics, setUserId, logEvent } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyB2kiq6Pg8iyd6eW3qMSmzyDQN4KO8u7j8',

  authDomain: 'avian-line-130123.firebaseapp.com',

  projectId: 'avian-line-130123',

  storageBucket: 'avian-line-130123.appspot.com',

  messagingSenderId: '717112684798',

  appId: '1:717112684798:web:a76b9d528cbe68e58b35af',
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);

export const setAnalyticsUserId = (userId: string) => {
  setUserId(analytics, userId);
};

export const logAnalyticsEvent = (eventName: string, eventParams?: { [key: string]: any }) => {
  logEvent(analytics, eventName, eventParams);
};

if (process.env.NODE_ENV === 'development') {
  const authEmulatorHost = process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST;
  const firestoreEmulatorHost = process.env.REACT_APP_FIRESTORE_EMULATOR_HOST;

  if (authEmulatorHost) {
    console.log('Using auth emulator at %s', authEmulatorHost);
    connectAuthEmulator(auth, `http://${authEmulatorHost}`);
  }
  if (firestoreEmulatorHost) {
    console.log('Using firestore emulator at %s', firestoreEmulatorHost);
    const [host, port] = firestoreEmulatorHost.split(':', 2);
    connectFirestoreEmulator(db, host, Number.parseInt(port));
  }
}
