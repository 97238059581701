import styled from 'styled-components';
import { theme } from './theme';

import {
  IconButton,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
} from '@mui/material';
import Video from 'src/components/Video';

export const RatingContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
`;

export const RatingSum = styled.span`
  margin: 0 10px;
  font-weight: bold;
  font-family: ${theme.typography.fontFamily};
`;

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

export const VideoCardDiv = styled.div`
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  font-family: ${theme.typography.fontFamily};
  width: 100%;
`;


export const StyledVideo = styled(Video)`
  width: 100%;
  max-width: 800px;
  height: auto;
  aspect-ratio: 16 / 9;
  border-radius: 8px;
  margin: 0 auto 20px;
  display: block;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 20px;

  th,
  td {
    border: none;
    padding: 6px 8px; // Reduced vertical padding
    text-align: left;
    font-size: 0.9em; // Slightly smaller font size
    line-height: 1.2; // Reduced line height
    vertical-align: top; // Align content to the top of cells
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }

  th {
    background-color: ${theme.colors.primary};
    color: white;
    font-weight: bold;
    padding-top: 8px; // Slightly more padding for header
    padding-bottom: 8px;
  }

  tbody tr {
    border-bottom: 1px solid #e0e0e0; // Light border between rows
  }

  tr:nth-child(even) {
    background-color: #f8f8f8;
  }

  tr:hover {
    background-color: #f0f0f0;
  }

  mark {
    background-color: yellow;
    padding: 0 2px;
    border-radius: 2px;
  }

  .column-select {
    width: 5%;
    padding: 0 4px; // Even smaller padding for checkbox column
  }
  .column-time { width: 8%; }
  .column-text { width: 40%; }
  .column-speaker { width: 22%; }
  .column-score { width: 9%; }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const StyledButton = styled.a`
  display: inline-block;
  padding: 12px 20px;
  font-size: ${theme.typography.fontSize};
  color: #fff;
  background-color: ${theme.colors.primary};
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.secondary};
  }
`;

export const DownloadButton = styled(StyledButton)``;

export const SubmitButton = styled(StyledButton).attrs({
  as: 'button',
})``;


export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const StyledDialog = styled(MuiDialog)`
  .MuiPaper-root {
    background-color: ${theme.colors.background};
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
`;

export const DialogTitle = styled(MuiDialogTitle)`
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.headerSize};
  color: ${theme.colors.text};
`;

export const DialogContent = styled(MuiDialogContent)`
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSize};
  color: ${theme.colors.text};
  padding: 20px;
`;

export const DialogActions = styled(MuiDialogActions)`
  padding: 20px;
`;

export const DialogButton = styled(StyledButton)`
  margin: 0 10px;

  &:last-child {
    margin-right: 0;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
`;


export const DateAdded = styled.span`
  font-size: 0.9em;
  color: ${theme.colors.lightText};
  font-family: ${theme.typography.fontFamily};
`;

export const FlagButton = styled(IconButton)`
  margin-left: auto;
  margin-right: 10px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
