import { getAuth, User } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Caption,
  DownloadButton,
  Grid,
  PageButton,
  PageInfo,
  PaginationContainer,
  StyledVideo,
  VideoCard,
  VideoWrapper,
} from '../styles/GeneratedContentList.style';

interface PaginationInfo {
  items: GeneratedContent[];
  total_pages: number;
  current_page: number;
  total_count: number;
}

interface GeneratedContent {
  id: string;
  gcs_uri: string;
  signedUrl?: string;
  streaming_gcs_uri?: string;
  caption?: string;
}

let cachedToken: string | null = null;
let tokenExpirationTime: number = 0;

const getIdToken = async (user: User): Promise<string> => {
  const currentTime = Date.now();
  if (cachedToken && tokenExpirationTime > currentTime) {
    return cachedToken;
  }

  try {
    const newToken = await user.getIdToken(false); // Try to get a cached token first
    cachedToken = newToken;
    // Set expiration time to 5 minutes before the actual expiration
    tokenExpirationTime = currentTime + 60 * 55 * 1000; // 55 minutes
    return newToken;
  } catch (error) {
    console.error('Error getting ID token:', error);
    throw error;
  }
};

const API_URL = process.env.REACT_APP_API_URL as string;

const GeneratedContentList: React.FC = () => {
  const { agentId } = useParams<{ agentId: string }>();
  const [content, setContent] = useState<GeneratedContent[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo | null>(null);
  const pageSize = 4;

  useEffect(() => {
    const fetchGeneratedContent = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const auth = getAuth();
        const user = auth.currentUser;
        if (!user) {
          throw new Error('No user logged in');
        }

        let idToken: string;
        try {
          idToken = await getIdToken(user);
        } catch (error) {
          if (error instanceof Error && error.message.includes('auth/quota-exceeded')) {
            console.warn('Token refresh quota exceeded. Using existing token.');
            idToken = await user.getIdToken(false);
          } else {
            throw error;
          }
        }

        console.log('Token obtained:', idToken.slice(0, 10) + '...');

        const response = await fetch(
          `${API_URL}/generated_content/${agentId}?page=${
            paginationInfo?.current_page || 1
          }&page_size=${pageSize}`,
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          },
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch generated content: ${response.statusText}`);
        }

        const data: PaginationInfo = await response.json();
        setContent(data.items);
        setPaginationInfo(data);
      } catch (error) {
        console.error('Error fetching generated content:', error);
        setError(error instanceof Error ? error.message : 'An unknown error occurred');
      } finally {
        setIsLoading(false);
      }
    };

    fetchGeneratedContent();
  }, [agentId, paginationInfo?.current_page]);

  const changePage = (newPage: number) => {
    if (paginationInfo) {
      setPaginationInfo({ ...paginationInfo, current_page: newPage });
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const renderPagination = () => {
    if (!paginationInfo) return null;

    const { current_page, total_pages } = paginationInfo;
    const pageNumbers = [];
    const maxVisiblePages = 5;

    let startPage = Math.max(1, current_page - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(total_pages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return (
      <PaginationContainer>
        <PageButton onClick={() => changePage(current_page - 1)} disabled={current_page === 1}>
          &lt;
        </PageButton>
        {pageNumbers.map((number) => (
          <PageButton
            key={number}
            onClick={() => changePage(number)}
            active={number === current_page}
          >
            {number}
          </PageButton>
        ))}
        <PageButton
          onClick={() => changePage(current_page + 1)}
          disabled={current_page === total_pages}
        >
          &gt;
        </PageButton>
        <PageInfo>
          Page {current_page} of {total_pages}
        </PageInfo>
      </PaginationContainer>
    );
  };

  return (
    <>
      <Grid>
        {content.map((item) => (
          <VideoCard key={item.id}>
            <VideoWrapper>
              <StyledVideo
                hlsUrl={`${API_URL}/video/generated_content/${encodeURIComponent(
                  item.id,
                )}/${encodeURIComponent(item.id)}.m3u8`}
                nativeUrl={`${API_URL}/video/generated_content/${encodeURIComponent(item.id)}`}
              />
            </VideoWrapper>
            {item.caption && <Caption>{item.caption}</Caption>}
            <DownloadButton href={item.gcs_uri} download>
              Download
            </DownloadButton>
          </VideoCard>
        ))}
      </Grid>
      {isLoading ? <div>Loading...</div> : renderPagination()}
    </>
  );
};

export default GeneratedContentList;
