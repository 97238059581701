import { GoogleAuthProvider, signInWithPopup, User } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { auth } from './firebase';
import { API_URL } from './utils/api';

const useAuth = () => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  const login = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        await fetch(`${API_URL}/auth/login`, {
          method: 'POST',
          body: JSON.stringify({ id_token: await currentUser.getIdToken() }),
          headers: { 'Content-Type': 'application/json' },
          // NOTE(@cwegrzyn): Needed to process the cookie from the API, at least on localhost
          // given different ports, but not sure if this is the correct approach or if it's
          // needed in deployment
          credentials: 'include',
        });
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return { user, loading, login };
};

export default useAuth;
