import { Block, Direction, SelectionAsset, Overlay } from 'shared-models';

export const overlay_example: Block = {
  description: 'Source Audio with Video With Transparent Overlay (CapCut Style)',
  example: {
    type: 'video with source audio and overlay',
    description: 'An interview with a person who is facing the camera and speaking.',
    text: 'It was the best time of my life.',
    video: {
      id: 'fake_id',
      description:
        "An interview with a person who is facing the camera and speaking. He's talking about sports.",
      transcript: [
        {
          index: 0,
          text: 'It was the best time of my life.',
        },
      ],
    } as SelectionAsset,
    overlay: {
      description: 'Overlay Name',
      path: 'path/to/selected_overlay.png',
    } as Overlay,
    audio: 'source-audio',
  } as Direction,
};

export const voa_image_example: Block = {
  description: 'Voice Over Audio with Background Image',
  example: {
    type: 'image with voice-over audio',
    description: 'Voice-over narration over a still image of a building with text.',
    text: "He claims that he didn't do the thing.",
    background: {
      description: 'Forboding image of a building',
      text: "He claims he didn't do the thing.",
    },
    audio: 'voice-over',
  } as Direction,
};

export const sa_video_example: Block = {
  description: 'Source Audio with Video',
  example: {
    type: 'video with source audio',
    description: 'An interview with a person who is facing the camera and speaking.',
    text: 'It was the best time of my life.',
    video: {
      id: 'fake_id',
      description:
        "An interview with a person who is facing the camera and speaking. He's talking about sports.",
      transcript: [
        {
          index: 0,
          text: 'It was the best time of my life.',
        },
      ],
    } as SelectionAsset,
    audio: 'source-audio',
  } as Direction,
};

export const voa_video_example: Block = {
  description: 'Voice Over Audio and Video',
  example: {
    type: 'video with voice-over audio',
    description: 'Cut to footage of a voice-over with a large crowd cheering at a stadium',
    text: 'After ten years they finally won the championship',
    video: {
      id: 'fake_id',
      description:
        'The video shows a large crowd of people gathered in and outside of a stadium wearing sports jerseys and cheering.',
    } as SelectionAsset,
    audio: 'voice-over',
  } as Direction,
};
