import { User } from 'firebase/auth';

let cachedToken: string | null = null;
let tokenExpirationTime: number = 0;

// TODO(@cwegrzyn): why do we need this if firebase seems to refresh its token as needed?
export const getIdToken = async (user: User): Promise<string> => {
  const currentTime = Date.now();
  if (cachedToken && tokenExpirationTime > currentTime) {
    return cachedToken;
  }

  try {
    const newToken = await user.getIdToken(false);
    cachedToken = newToken;
    tokenExpirationTime = currentTime + 60 * 55 * 1000;
    return newToken;
  } catch (error) {
    console.error('Error getting ID token:', error);
    throw error;
  }
};
