import React from 'react';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db, auth } from './firebase';

const MEMBERSHIPS_COLLECTION = `memberships`;
const ORGANIZATIONS_COLLECTION = `organizations`;

export interface Organization {
  id: string;
  name: string;
  // Add other organization properties as needed
}

export const fetchUserOrganizations = async (): Promise<Organization[]> => {
  console.log('Starting fetchUserOrganizations function');

  const user = auth.currentUser;
  console.log('Current user:', user ? user.uid : 'No user logged in');

  if (!user) {
    console.log('No user logged in, returning empty array');
    return [];
  }

  try {
    console.log('Attempting to fetch user organizations');

    const membershipsRef = collection(db, MEMBERSHIPS_COLLECTION);
    console.log('Created memberships collection reference');

    const q = query(membershipsRef, where('userId', '==', user.uid));
    console.log('Created query for memberships with userId:', user.uid);

    console.log('Executing query to get memberships from %s', MEMBERSHIPS_COLLECTION);
    console.log(process.env)
    const membershipsSnapshot = await getDocs(q);
    console.log('Memberships snapshot received, number of docs:', membershipsSnapshot.size);

    const organizationPromises = membershipsSnapshot.docs.map(async (membershipDoc) => {
      const membershipData = membershipDoc.data();
      console.log('Processing membership document:', membershipDoc.id, 'Data:', membershipData);

      const orgId = membershipData.organizationId;
      console.log('Extracted organizationId:', orgId);

      const orgDocRef = doc(db, ORGANIZATIONS_COLLECTION, orgId);
      console.log('Created document reference for organization:', orgId);

      console.log('Fetching organization document');
      const orgDoc = await getDoc(orgDocRef);

      if (orgDoc.exists()) {
        console.log('Organization document found for:', orgId);
        const orgData = orgDoc.data();
        console.log('Organization data:', orgData);
        return { id: orgId, ...orgData } as Organization;
      } else {
        console.log('No organization document found for:', orgId);
        return null;
      }
    });

    console.log('Waiting for all organization promises to resolve');
    const organizations = await Promise.all(organizationPromises);

    const filteredOrganizations = organizations.filter((org): org is Organization => org !== null);
    console.log('Fetched and filtered organizations:', filteredOrganizations);

    return filteredOrganizations;
  } catch (error) {
    console.error('Error in fetchUserOrganizations:', error);
    console.error('Stack trace:', (error as Error).stack);
    return [];
  } finally {
    console.log('Finished fetchUserOrganizations function');
  }
};

export const useOrganizations = () => {
  const [organizations, setOrganizations] = React.useState<Organization[]>([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const loadOrganizations = async () => {
      setLoading(true);
      const orgs = await fetchUserOrganizations();
      setOrganizations(orgs);
      setLoading(false);
    };

    loadOrganizations();
  }, []);

  return { organizations, loading };
};
