// src/styles/App.style.ts

import styled from 'styled-components';
import { FormControl, MenuItem, Select, SelectProps, styled as muiStyled } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import { theme } from './theme';

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: ${theme.colors.background};
`;

export const SearchContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
`;

export const Title = styled.h1`
  font-size: ${theme.typography.headerSize};
  margin-bottom: 20px;
  color: ${theme.colors.primary};
  text-align: center;
  font-family: ${theme.typography.fontFamily};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SearchButton = styled.button`
  background: #4a90e2;
  color: white;
  border: none;
  padding: 12px 16px;
  cursor: pointer;
  transition: background 0.3s ease;
  &:hover {
    background: #3a7bd5;
  }
`;

export const OptionsBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
`;

export const AdvancedOptionsToggle = styled.button`
  background: none;
  border: none;
  color: ${theme.colors.primary};
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: ${theme.typography.fontSize};
  font-family: ${theme.typography.fontFamily};
  &:hover {
    text-decoration: underline;
  }
`;

export const FilterSelect = styled(Select)<SelectProps<string>>`
  && {
    background-color: transparent;
    border: none;
    border-left: 1px solid #e0e0e0;
    border-radius: 0;
    font-size: ${theme.typography.fontSize};
    font-family: ${theme.typography.fontFamily};
    padding: 8px 12px;
    color: ${theme.colors.text};
    .MuiSelect-select {
      padding-right: 24px;
    }
    &:before,
    &:after {
      display: none;
    }
    .MuiSelect-icon {
      right: 4px;
    }
  }
`;
export const AdvancedOptions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
`;

export const SearchTypeToggle = styled.div`
  display: flex;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
`;

export const ToggleButton = styled.button<{ active: boolean }>`
  padding: 8px 12px;
  background-color: ${(props) => (props.active ? '#4a90e2' : 'transparent')};
  color: ${(props) => (props.active ? 'white' : '#4a90e2')};
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: ${(props) => (props.active ? '#3a7bd5' : '#f0f0f0')};
  }
`;

export const SpinnerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 999;
`;

export const ScrollingBanner = styled.div`
  width: 100%;
  overflow: hidden;
  background-color: #ffcccc;
  padding: 10px 0;
  white-space: nowrap;
`;

export const ScrollingText = styled.div`
  display: inline-block;
  padding-left: 100%;
  animation: scroll 20s linear infinite;

  @keyframes scroll {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
  }
`;

export const BannerText = styled.span`
  color: red;
  font-size: ${theme.typography.fontSize};
  font-family: ${theme.typography.fontFamily};
  font-weight: bold;
`;

export const StyledFormControl = muiStyled(FormControl)(({ theme }) => ({
  minWidth: 200,
  marginRight: '20px',
}));

export const StyledMenuItem = muiStyled(MenuItem)(({ theme }) => ({
  whiteSpace: 'normal',
  wordWrap: 'break-word',
}));

// Keeping these for potential future use or backwards compatibility
export const Button = styled.button`
  padding: 8px 16px;
  font-size: ${theme.typography.fontSize};
  cursor: pointer;
  background-color: ${theme.colors.primary};
  color: #fff;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${theme.colors.secondary};
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const RadioButton = styled.label`
  margin-right: 10px;
  font-size: ${theme.typography.fontSize};
  display: flex;
  align-items: center;

  input {
    margin-right: 5px;
  }
`;

export const RadioLabel = styled.div`
  margin-bottom: 5px;
  font-size: ${theme.typography.fontSize};
  color: ${theme.colors.lightText};
`;

export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

export const Label = styled.label`
  font-weight: bold;
  color: ${theme.colors.lightText};
  margin-bottom: 5px;
`;

export const FilterRow = styled(FormRow)`
  margin-top: 15px;
`;

export const SubmitButton = styled(Button)`
  align-self: flex-end;
  margin-top: 15px;
`;

export const SearchBar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
`;

export const SearchInput = styled.input`
  flex-grow: 1;
  padding: 12px 16px;
  border: none;
  font-size: ${theme.typography.fontSize};
  font-family: ${theme.typography.fontFamily};
  background-color: transparent;
  &:focus {
    outline: none;
  }
`;

export const FilterSelectWrapper = styled.div`
  width: 150px;
  margin-right: 8px;
`;

export const MaxResultsWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const MaxResultsLabel = styled.label`
  font-size: ${theme.typography.fontSize};
  font-family: ${theme.typography.fontFamily};
  margin-right: 8px;
  color: ${theme.colors.lightText};
`;

export const MaxResultsSelect = styled.select`
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #f5f5f5;
  font-size: ${theme.typography.fontSize};
  font-family: ${theme.typography.fontFamily};
`;

export const FilterInput = styled(SearchInput)`
  width: 200px;
`;

export const SmallInput = styled(SearchInput)`
  max-width: 80px;
`;
export const LibraryToggle = styled.div`
  display: flex;
  margin-right: 16px;
`;
