import React, { useState } from 'react';
import { Asset, Utterance } from 'shared-models';
import {
  VideoCardDiv,
  StyledVideo,
  ContentContainer,
  CardHeader,
  DateAdded,
  Table,
  ButtonContainer,
  DownloadButton,
  SubmitButton,
  CheckboxContainer
} from '../styles/VideoList.style';
import { Checkbox, Chip, Tab, Tabs, Box } from '@mui/material';
import { API_URL } from 'src/utils/api';

interface VideoCardProps {
  asset: Asset;
  handleSubmitClick: (state: AssetEditorState) => void | Promise<void>;
  searchTerm: string;
}

interface AssetEditorState {
  id: string;
  checkedUtterances: boolean[];
}

interface TabsContainerProps {
  asset: Asset;
  checkedUtterances: boolean[];
  handleCheckboxChange: (utteranceIndex: number) => void;
  renderChips: (items: string[]) => React.ReactNode;
  searchTerm: string;
  highlightText: (text: string, highlight: string) => React.ReactNode;
}

const VideoCard: React.FC<VideoCardProps> = ({ asset, handleSubmitClick, searchTerm }) => {
  const [checkedUtterances, setCheckedUtterances] = useState<boolean[]>([]);

  const handleCheckboxChange = (utteranceIndex: number) => {
    setCheckedUtterances((prev) => {
      const updatedAssetUtterances = [...prev];
      updatedAssetUtterances[utteranceIndex] = !updatedAssetUtterances[utteranceIndex];
      return updatedAssetUtterances;
    });
  };

  return (
    <VideoCardDiv>
      <StyledVideo
        hlsUrl={`${API_URL}/video/assets/${encodeURIComponent(asset.id)}/${encodeURIComponent(asset.id)}.m3u8`}
        nativeUrl={`${API_URL}/video/assets/${encodeURIComponent(asset.id)}`}
      />
      <ContentContainer>
        <CardHeader>
          <DateAdded>{formatDate(asset.metadata.date_processed)}</DateAdded>
        </CardHeader>
        <TabsContainer
          asset={asset}
          checkedUtterances={checkedUtterances}
          handleCheckboxChange={handleCheckboxChange}
          renderChips={renderChips}
          searchTerm={searchTerm}
          highlightText={highlightText}
        />
        <ButtonContainer>
          <DownloadButton
            href={`${API_URL}/video/assets/${encodeURIComponent(asset.id)}`}
            download
            target="_blank"
            rel="noopener noreferrer"
          >
            Download Video
          </DownloadButton>
          <SubmitButton onClick={() => handleSubmitClick({ id: asset.id, checkedUtterances })}>
            Submit for Editing
          </SubmitButton>
        </ButtonContainer>
      </ContentContainer>
    </VideoCardDiv>
  );
};

const TabsContainer: React.FC<TabsContainerProps> = ({
  asset,
  checkedUtterances,
  handleCheckboxChange,
  renderChips,
  searchTerm,
  highlightText
}) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <Tabs value={selectedTab} onChange={handleChange}>
        <Tab label="Machine Annotations" />
        <Tab label="Transcription" />
      </Tabs>
      <TabPanel value={selectedTab} index={0}>
        {asset.annotations && (
          <Table>
            <tbody>
              <tr>
                <td>Description</td>
                <td>{highlightText(asset.annotations.description, searchTerm)}</td>
              </tr>
              {asset.annotations.topics && (
                <tr>
                  <td>Topics</td>
                  <td>{renderChips(asset.annotations.topics)}</td>
                </tr>
              )}
              {asset.annotations?.speakers && (
                <tr>
                  <td>Speakers</td>
                  <td>
                    {renderChips(
                      Object.values(asset.annotations.speakers).map(speaker =>
                        typeof speaker === 'string'
                          ? speaker
                          : (speaker as Record<string, any>).name || 'Unknown'
                      )
                    )}
                  </td>
                </tr>
              )}
              {asset.annotations.vibes && (
                <tr>
                  <td>Vibes</td>
                  <td>{renderChips(asset.annotations.vibes)}</td>
                </tr>
              )}
            </tbody>
          </Table>
        )}
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        {asset.transcription && asset.transcription.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <th className="column-select">Select</th>
                <th className="column-time">Start</th>
                <th className="column-time">End</th>
                <th className="column-text">Text</th>
                <th className="column-speaker">Speaker</th>
                <th className="column-score">Score</th>
              </tr>
            </thead>
            <tbody>
              {asset.transcription.map((utterance: Utterance, idx: number) => {
                const isHighlighted = searchTerm && utterance.text.toLowerCase().includes(searchTerm.toLowerCase());
                return (
                  <tr key={idx} style={isHighlighted ? { backgroundColor: 'yellow' } : {}}>
                     <td className="column-select">
                      <CheckboxContainer>
                        <Checkbox
                          checked={checkedUtterances[idx] || false}
                          onChange={() => handleCheckboxChange(idx)}
                          size="small" // Use a smaller checkbox
                        />
                      </CheckboxContainer>
                    </td>
                    <td className="column-time">{formatNumber(utterance.start_time)}</td>
                    <td className="column-time">{formatNumber(utterance.end_time)}</td>
                    <td className="column-text">{highlightText(utterance.text, searchTerm)}</td>
                    <td className="column-speaker">
                      {asset.annotations?.speakers && utterance.speaker_id
                        ? asset.annotations.speakers[utterance.speaker_id]
                        : utterance.speaker || "Unknown Speaker"}
                    </td>
                    <td className="column-score">{utterance.score} / 100</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <p>No transcription available for this asset.</p>
        )}
      </TabPanel>
    </>
  );
};

function TabPanel(props: { children?: React.ReactNode; index: number; value: number }) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function formatDate(seconds: number): string {
  const date = new Date(seconds * 1000);
  return date.toLocaleString();
}

function formatNumber(num: number): string {
  return num.toFixed(2);
}

const getChipColor = (content: string) => {
  let hash = 0;
  for (let i = 0; i < content.length; i++) {
    hash = content.charCodeAt(i) + ((hash << 5) - hash);
  }
  const hue = hash % 360;
  return `hsl(${hue}, 70%, 80%)`;
};

const renderChips = (items: string[]) => {
  return items.map((item, index) => (
    <Chip key={index} label={item} style={{ backgroundColor: getChipColor(item), margin: '2px' }} />
  ));
};

const highlightText = (text: string, highlight: string) => {
  if (!highlight.trim()) {
    return <span>{text}</span>;
  }
  const regex = new RegExp(`(${highlight})`, 'gi');
  const parts = text.split(regex);
  return (
    <span>
      {parts.filter(String).map((part, i) =>
        regex.test(part) ? <mark key={i}>{part}</mark> : <span key={i}>{part}</span>
      )}
    </span>
  );
};

export default VideoCard;
