import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import { SelectChangeEvent } from '@mui/material';
import { logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useMemo, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import GeneratedContentList from './components/GeneratedContentList';
import InfluencerAgents from './components/InfluencerAgents';
import ScriptGen from './components/ScriptGen';
import Spinner from './components/Spinner';
import Toolbar from './components/Toolbar';
import TopicAssets from './components/TopicAssets';
import VideoList from './components/VideoList';
import Zeitgeist from './components/Zeitgeist';
import LiveEventsPage from './components/LiveEventsPage';
import { analytics } from './firebase';
import { useOrganizations } from './organizationUtils';
import {
  AdvancedOptions,
  AdvancedOptionsToggle,
  AppContainer,
  BannerText,
  Button,
  ContentContainer,
  Form,
  LibraryToggle,
  MaxResultsLabel,
  MaxResultsSelect,
  MaxResultsWrapper,
  OptionsBar,
  ScrollingBanner,
  ScrollingText,
  SearchBar,
  SearchButton,
  SearchContainer,
  SearchInput,
  SearchTypeToggle,
  SpinnerWrapper,
  Title,
  ToggleButton,
} from './styles/App.style';
import useAuth from './useAuth';

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, loading, login } = useAuth();


  if (!user) {
    return <Button onClick={login}>Login with Google</Button>;
  }

  return <>{children}</>;
};

const App: React.FC = () => {
  const [assets, setAssets] = useState<{ id: string }[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { user, loading: authLoading, login } = useAuth();
  const [filterType, setFilterType] = useState<string>('none');
  const [filterValue, setFilterValue] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<string>('assetFinder');
  const { organizations, loading: orgLoading } = useOrganizations();
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false);
  const [hasSearched, setHasSearched] = useState<boolean>(false);
  const [localSearchType, setLocalSearchType] = useState<string>('similarity');
  const [localK, setLocalK] = useState<number>(10);
  const [library, setLibrary] = useState<string>('all');

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(analytics, user.uid);
        setUserProperties(analytics, {
          account_created_at: user.metadata.creationTime,
          last_sign_in_at: user.metadata.lastSignInTime,
        });
        logEvent(analytics, 'login', {
          method: user.providerData[0]?.providerId || 'unknown',
        });
      } else {
        setUserId(analytics, null);
        logEvent(analytics, 'logout');
      }
    });

    return () => unsubscribe();
  }, []);

  const handleLogin = () => {
    login();
  };

  const checkAuth = () => {
    if (authLoading) {
      return <Spinner />;
    }
    if (!user) {
      return <Button onClick={handleLogin}>Login with Google</Button>;
    }
    return null;
  };


  const handleNavigate = (page: string) => {
    setCurrentPage(page);
  };

  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    setFilterType(event.target.value as string);
  };

  const renderContent = () => {
    return (
      <>
        <SearchContainer>
          <Title>Welcome the Experimental Machine</Title>
          Please use the toolbar to select your path forward.
        </SearchContainer>
      </>
    );
  };

  return (
    <Router>
      <AppContainer>
        <ScrollingBanner>
          <ScrollingText>
            <BannerText>THIS IS AN EXPERIMENTAL SITE - SORRY IF THINGS BREAK &lt;3</BannerText>
          </ScrollingText>
        </ScrollingBanner>
        <Toolbar />
        <ContentContainer>
          <Routes>
            <Route path="/" element={<ProtectedRoute>{renderContent()}</ProtectedRoute>} />
            <Route
              path="/influencer-agents"
              element={
                <ProtectedRoute>
                  <InfluencerAgents />
                </ProtectedRoute>
              }
            />
            <Route
              path="/generated-content/:agentId"
              element={
                <ProtectedRoute>
                  <GeneratedContentList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/live-events"
              element={
                <ProtectedRoute>
                  <LiveEventsPage />
                </ProtectedRoute>
              }
            />
          </Routes>
        </ContentContainer>
      </AppContainer>
    </Router>
  );
};

export default App;
