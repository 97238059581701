// src/styles/GeneratedContentList.style.ts

import Video from 'src/components/Video';
import styled from 'styled-components';
import { theme } from './theme';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

export const VideoCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  background-color: #fff;
`;

export const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 177.78%; // 16:9 aspect ratio
`;

export const StyledVideo = styled(Video)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const FullScreenVideo = styled(StyledVideo)`
  object-fit: contain;
  background-color: black;
`;

export const Caption = styled.p`
  padding: 10px;
  margin: 0;
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSize};
  color: ${theme.colors.text};
  background-color: ${theme.colors.background};
`;

export const DownloadButton = styled.a`
  display: block;
  width: 100%;
  padding: 12px 16px;
  background-color: ${theme.colors.primary};
  color: white;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSize};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${theme.colors.secondary};
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-family: ${theme.typography.fontFamily};
`;

export const PageButton = styled.button<{ active?: boolean }>`
  margin: 0 5px;
  padding: 8px 12px;
  background-color: ${(props) => (props.active ? theme.colors.secondary : theme.colors.primary)};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSize};
  transition: background-color 0.3s ease;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: ${theme.colors.secondary};
  }
`;

export const PageInfo = styled.span`
  margin: 0 10px;
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSize};
  color: ${theme.colors.text};
`;

export const LoadingText = styled.div`
  text-align: center;
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSize};
  color: ${theme.colors.text};
  margin-top: 20px;
`;

export const ErrorText = styled.div`
  text-align: center;
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSize};
  color: red;
  margin-top: 20px;
`;
