import { logAnalyticsEvent } from '../firebase';

export const trackVideoCardInteraction = (actionType: string, assetId: string, additionalParams?: { [key: string]: any }) => {
  logAnalyticsEvent('video_card_interaction', {
    action_type: actionType,
    asset_id: assetId,
    ...additionalParams,
  });
};

export const trackTabView = (tabName: string, assetId: string) => {
  trackVideoCardInteraction('tab_view', assetId, { tab_name: tabName });
};

export const trackDownload = (assetId: string) => {
  trackVideoCardInteraction('download', assetId);
};

export const trackSubmitForEditing = (assetId: string) => {
  trackVideoCardInteraction('submit_for_editing', assetId);
};

export const trackRating = (assetId: string, rating: 'up' | 'down') => {
  trackVideoCardInteraction('rating', assetId, { rating });
};

export const trackFlagContent = (assetId: string) => {
  trackVideoCardInteraction('flag_content', assetId);
};

export const trackAgentCreation = (agentId: string, theme: string, organizationId: string) => {
  logAnalyticsEvent('agent_created', {
    agent_id: agentId,
    theme,
    organization_id: organizationId,
  });
};

export const trackAgentUpdate = (agentId: string, theme: string, organizationId: string) => {
  logAnalyticsEvent('agent_updated', {
    agent_id: agentId,
    theme,
    organization_id: organizationId,
  });
};

export const trackAgentDeletion = (agentId: string, theme: string, organizationId: string) => {
  logAnalyticsEvent('agent_deleted', {
    agent_id: agentId,
    theme,
    organization_id: organizationId,
  });
};

export const trackAgentDialogOpen = (action: 'create' | 'edit') => {
  logAnalyticsEvent('agent_dialog_opened', { action });
};

export const trackAgentDialogClose = (action: 'save' | 'cancel') => {
  logAnalyticsEvent('agent_dialog_closed', { action });
};

export const trackAgentViewContent = (agentId: string, theme: string) => {
  logAnalyticsEvent('agent_view_content', {
    agent_id: agentId,
    theme,
  });
};